import React from "react"
import "../wdyr"
import Reset from "../components/user/reset"
import SEO from "../components/seo"

import { Translations } from '../utils/translations';
import { isBoolean } from "../utils/global";
const ResetPage = props => {
  return (
    <>
      {!isBoolean(process.env.LOGIN_WITHOUT_PASSWORD) ? (
        <React.Fragment>
          <SEO title="Reset" />
          <Reset />
        </React.Fragment>
      ) : (
        <h3 className="d-xs-flex xs-center xs-middle width-full">
         {Translations.loginWithoutPasswordPage.resetWarning}
        </h3>
      )}
    </>
  )
}

export default ResetPage
